import axios, { AxiosError, AxiosResponse } from "axios";
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { GetPartnersResponse } from "src/types/apiResponse";
import { Dashboard } from "src/types/dashboard";
import { Partner } from "src/types/partner";
import { HasLabelAndValue } from "src/types/shared";
import { FunctionComponentProps, FunctionComponentReturnType } from "src/types/sharedReact";
import { getFromApi } from "src/utils/getFromApi";
import RouteBuilder from "src/utils/routing/RouteBuilder";
import { useRouteBuilder } from "src/utils/routing/RouteBuilderContext";
import { convertNameAndCodeToLabelAndValue } from "src/utils/typeConversions";
import DropdownSelect from "../formElements/DropdownSelect/DropdownSelect";
import Form from "../formElements/Form/Form";
import RadioButtonGroup from "../formElements/RadioButtonGroup/RadioButtonGroup";
import TextInput from "../formElements/TextInput/TextInput";
import 'src/components/ManageDashboardForm/ManageDashboardForm.css';

interface ManageDashboardFormProps extends FunctionComponentProps {
    formId: string;
    dashboard?: Dashboard;
    refreshData?: () => void;
}

export interface ManageDashboardFormRef {
    resetForm: () => void;
}

function ManageDashboardFormInner (
    {
        formId,
        dashboard,
        refreshData
    }: ManageDashboardFormProps,
    ref: ForwardedRef<ManageDashboardFormRef>
): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const [accessTypeCode, setAccessTypeCode] = useState<string | null>(dashboard?.partner.type ?? null);
    const [partnerCode, setPartnerCode] = useState<string | null>(dashboard?.partner.code ?? null);
    const [dashboardName, setDashboardName] = useState<string | null>(dashboard?.dashboardName ?? null);
    const [dashboardDescription, setDashboardDescription] = useState<string | null>(dashboard?.dashboardDescription ?? null);
    const [dashboardUrl, setDashboardUrl] = useState<string | null>(dashboard?.dashboardUrl ?? null);
    const [error, setError] = useState<AxiosError | null>(null);

    const [partnersResponse, setPartnersResponse] = useState<GetPartnersResponse | null>(null);
    const getPartnersApiRoute: string = routeBuilder.api.getAllPartners();

    // Partners option change based on selected access type
    const [partners, setPartners] = useState<Partner[]>([]);
    useEffect(() => {
        setPartners(partnersResponse?.partners[accessTypeCode ?? ''] ?? []);
    }, [accessTypeCode, partnersResponse]);

    useEffect(getFromApi(getPartnersApiRoute, setPartnersResponse, setError), [getPartnersApiRoute]);

    function cleanDashboardUrlForWAF(url: string): string {
        url = url?.trim();
        if(url?.match(new RegExp("^(((?:([a-zA-Z]{3,5})://)([\\w-]+\\.?)+){0,1}/.*)$"))) {
            url = url.replace(/^[a-zA-Z]{3,5}:\/\/[a-zA-Z0-9_.:-]+/i, "")
        }
        return url;
    }

    async function handleSubmit(): Promise<void> {
        const trimmedAccessTypeCode = accessTypeCode?.trim();
        const trimmedPartnerCode = partnerCode?.trim();
        const trimmedDashboardName = dashboardName?.trim();
        const trimmedDashboardUrl = cleanDashboardUrlForWAF(dashboardUrl);

        if(
            !trimmedAccessTypeCode ||
            !trimmedPartnerCode ||
            !trimmedDashboardName ||
            !trimmedDashboardUrl
        ) {
            setAccessTypeCode(trimmedAccessTypeCode || '');
            setPartnerCode(trimmedPartnerCode || '');
            setDashboardName(trimmedDashboardName || '');
            setDashboardUrl(trimmedDashboardUrl || '');
            return; // Abort submission
        }

        const newDashboard: Dashboard = {
            dashboardId: dashboard?.dashboardId ?? undefined,
            dashboardName: trimmedDashboardName,
            dashboardDescription: dashboardDescription ?? '',
            dashboardUrl: trimmedDashboardUrl,
            partner: {
                type: trimmedAccessTypeCode,
                code: trimmedPartnerCode
            }
        };

        const upsert: Promise<AxiosResponse> = dashboard ?
            axios.put(routeBuilder.api.editDashboard({ dashboardId: newDashboard.dashboardId }), newDashboard) :
            axios.post(routeBuilder.api.createDashboard(), newDashboard);

        await upsert
            .then(refreshData)
            .catch(console.error);
    }

    function resetForm() {
        setAccessTypeCode(null);
        setPartnerCode(null);
        setDashboardName(null);
        setDashboardDescription(null);
        setDashboardUrl(null);
    }

    useImperativeHandle(ref, (): ManageDashboardFormRef => ({
        resetForm
    }));

    return (
        <Form className="manage-dashboard-form" onSubmit={handleSubmit} name={formId} id={formId}>
            <RadioButtonGroup
                label="Access Type"
                options={partnersResponse?.accessTypes.map(convertNameAndCodeToLabelAndValue) ?? []}
                value={accessTypeCode}
                onChange={setAccessTypeCode}
                required
            />
            <DropdownSelect
                label="Partner"
                options={partners.map(convertNameAndCodeToLabelAndValue)}
                value={partnerCode}
                onChange={setPartnerCode}
                required
            />
            <TextInput
                label="Dashboard Name"
                value={dashboardName}
                onChange={setDashboardName}
                required
            />
            <TextInput
                label="Dashboard URL (The host will be removed when updated)"
                value={dashboardUrl}
                onChange={setDashboardUrl}
                required
            />
            <TextInput
                label="Dashboard Description"
                value={dashboardDescription}
                onChange={setDashboardDescription}
                textarea
            />
        </Form>
    )
}

const ManageDashboardForm = forwardRef(ManageDashboardFormInner);

export default ManageDashboardForm;
