import { Button, Spinner } from "@c1/gravity-react";
import React, { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react";
import { FunctionComponentProps, FunctionComponentReturnType } from "src/types/sharedReact";
import TextInput from "../formElements/TextInput/TextInput";
import GravityIcon from "../icons/GravityIcon/GravityIcon";
import Form from "../formElements/Form/Form";
import 'src/components/DatasetValidationForm/DatasetValidationForm.css';
import { GetDatasetResponse } from "../../types/apiResponse";
import axios, {AxiosError} from "axios";
import RouteBuilder from "../../utils/routing/RouteBuilder";
import {useRouteBuilder} from "../../utils/routing/RouteBuilderContext";
import FormModal from "../modals/FormModal/FormModal";

interface DatasetValidationFormProps extends FunctionComponentProps {
    setShowValidatePopup: Dispatch<SetStateAction<boolean>>,
    showValidatePopup: boolean,
    addReportCategory: () => void,
    setReportCategoryCode: Dispatch<SetStateAction<string>>,
    reportCategoryCode: string
}

function DatasetValidationForm(
    {
        setShowValidatePopup,
        showValidatePopup,
        setReportCategoryCode,
        addReportCategory,
        reportCategoryCode
    }: DatasetValidationFormProps
): FunctionComponentReturnType {
    const [datasetResponseLoaded, setDatasetLoaded] = useState<boolean>(false);
    const [datasetValidated, setDatasetValidated] = useState<boolean>(null);
    const [datasetIsLoading, setDatasetIsLoading] = useState<boolean>(null);
    const [datasetId, setDatasetId] = useState<string>('');
    const [dataset, setDataset] = useState<GetDatasetResponse | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const [validationErrors, setValidationErrors] = useState([])
    const routeBuilder: RouteBuilder = useRouteBuilder();

    useEffect(() => {
        validateDataset();
    }, [dataset]);

    useEffect(() => {
        handleError();
    }, [error]);

    function validateDataset(): void {
        const expectedObjectKey = '/Reports/' + routeBuilder.getAccessTypeCode() + '/' + routeBuilder.getPartnerCode() + '/' + reportCategoryCode + '/';

        if (dataset) {
            if (dataset.objectKey == expectedObjectKey) {
                setDatasetValidated(true);
            }
            else {
                setDatasetValidated(false);
                setValidationErrors([...validationErrors, validationFailureMessage("Invalid Object Key: expected ", expectedObjectKey)]);
                console.error("Object key does not match");
            }
            setDatasetLoaded(true);
            setDatasetIsLoading(false);
        }
    }

    function handleError(): void {
        if (error) {
            setDatasetIsLoading(false);
            setDatasetLoaded(true);
            setDataset(null);
            setDatasetValidated(false);

            console.error("Dataset not found");
            setValidationErrors([...validationErrors, validationFailureMessage("Dataset not found")]);
        }
    }

    async function handleValidateDatasetClick() {
        setValidationErrors([]);
        setDatasetIsLoading(true);

        await axios.get(routeBuilder.api.getDataset(datasetId))
            .then((res) => {
                setDataset(res.data);
            })
            .catch(e => {
                setError(e);
            });
    }

    function handleSubmitAddReport(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault();
        event.stopPropagation();

        addReportCategory();
        setReportCategoryCode('');
        handleCancel();
    }

    function handleCancel() {
        setShowValidatePopup(false);
        setDatasetValidated(null);
        setDatasetIsLoading(null);
        setDatasetLoaded(false);
        setDatasetId('');
        setDataset(null);
    }

    function validationSuccessMessage() {
        return (
            <div className="validation-message-success">
                <GravityIcon
                    type="CheckmarkLined"
                    role="img"
                    alt="Dataset validation"
                    style={{width: "1.2rem", height: "1.2rem"}}
                />
                <span style={{fontSize: '14px'}}>Dataset validated</span>
            </div>
        );
    }

    function validationFailureMessage(message: string, expectedObjectKey?: string) {
        return (
            <div className="validation-message-failure">
                <GravityIcon
                    type="CloseLined"
                    role="img"
                    alt="Dataset validation"
                    style={{width: "1.2rem", height: "1.2rem"}}
                />
                <span style={{fontSize: '14px', }}>{message} <span style={{fontFamily: 'monospace'}}>{expectedObjectKey}</span> </span>
            </div>
        );
    }

    return (
        <FormModal
            active={showValidatePopup}
            title="Add Report"
            formId="validate-dataset"
            okButtonType="progressive"
            okButtonDisabled={!datasetValidated}
            onCancel={handleCancel}
        >
            <div className={"register-text"}>
                Reach out to the COPP Support slack channel for additional information or help registering datasets.
            </div>

            <Form className="validate-dataset-form" id="validate-dataset" name="validate=dataset" onSubmit={handleSubmitAddReport}>
                <TextInput label={"Dataset ID"} value={datasetId} onChange={setDatasetId}
                           errors={datasetValidated === false ? validationErrors : []}/>

                <div className="validate-button-line">
                    <Button className="validate-dataset-button" disabled={!datasetId} onClick={handleValidateDatasetClick} compact>
                        Validate
                    </Button>

                    <Spinner active={datasetIsLoading}/>

                    {datasetResponseLoaded ? (datasetValidated ? validationSuccessMessage() : null) : null}
                </div>
            </Form>
        </FormModal>
    )
}

export default DatasetValidationForm;
