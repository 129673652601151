import { Button } from '@c1/gravity-react';
import React, { useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import {
  AuthenticationContextState,
  useAuthenticationContext
} from 'src/contextProviders/AuthenticationProvider/AuthenticationContext';
import "./QuicksightDashboard.css";
import axios from 'axios';
import { navigate, useLocation, useParams } from '@reach/router';
import iframeResolutionHelper from 'src/utils/iframeResolutionHelper';
import { GetQuicksightBoardInfoResponse } from 'src/types/apiResponse';
import EmbeddedFrame from 'src/components/EmbeddedFrame/EmbeddedFrame';


function QuicksightDashboard(): FunctionComponentReturnType {
  const [anonUrl, setAnonUrl] = useState<string>('');
  const [didUrlRequestFail, setDidUrlRequestFail] = useState<boolean>(false);
  const routeBuilder: RouteBuilder = useRouteBuilder();
  const [errorOnEmbedView, setErrorOnEmbedView] = useState<boolean>(false);
  const { pinboardId } = useParams();
  const location = useLocation();
  const { user }: AuthenticationContextState = useAuthenticationContext();
  const apiRouteFail: string = routeBuilder.api.trackDashboardFail({ dashboardId: pinboardId });
  const apiRouteGetQuicksightBoardInfo: string = routeBuilder.api.getQuicksightBoardInfo({ dashboardId: pinboardId });

  useEffect(() => {
    if(anonUrl) {
        iframeResolutionHelper(null, (iframeDoc, status) =>{
            console.log(status);
        })
    }
  }, [anonUrl])
  useEffect(() => {
    if (document.getElementById("page-template-title-element")) {
       document.getElementById("page-template-title-element").textContent = "...";
    }
    axios.get<GetQuicksightBoardInfoResponse>(apiRouteGetQuicksightBoardInfo).then(function (response) {
        if(response.status == 200) {
            let tempAnonUrl = "https://us-east-1.quicksight.aws.amazon.com" + response.data.dashboardUrl;
            if(tempAnonUrl && (/^https:\/\/us-[a-z]+-[1-9]\.quicksight\.aws\.amazon\.com\/embed/).test(tempAnonUrl)) {
                setAnonUrl(tempAnonUrl);
                if (document.getElementById("page-template-title-element")) {
                    document.getElementById("page-template-title-element").textContent = response.data.dashboardName;
                }
            }
            else {
                setDidUrlRequestFail(true);
            }
        }
      }).catch(function (error) {
        setDidUrlRequestFail(true);
      });
  }, [setAnonUrl]);


  return (
    <div id={'quicksight-page-body'}>
      <Button id={'copp-button-return'} className="grv-margin--tiny" type="regressive" onClick={() => navigate(routeBuilder.client.toDashboardSelectionPage())} compact>Return</Button>
      <br />
         <div className={"quicksight-container"}>
            {!didUrlRequestFail ?
                <EmbeddedFrame url={anonUrl}/> :
                "Failed to load dashboard"
            }
        </div>
    </div>
  );
}

export default QuicksightDashboard;
